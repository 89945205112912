import {
    DataSharedServicesAction,
    DataSharedServicesActions,
} from './data-shared-services.actions';
import {
    dataSharedServicesInitialState,
    DataSharedServicesState,
} from './data-shared-services.state';

export function dataSharedServicesReducer(
    state = dataSharedServicesInitialState,
    action: DataSharedServicesAction
): DataSharedServicesState {
    switch (action.type) {
        // CENTRO DE CUSTO
        case DataSharedServicesActions.CADASTRO_CENTRO_DE_CUSTO_RECARREGAR: {
            return {
                ...state,
                cadastroCentroDeCusto: action.payload,
            };
        }
        // GRUPO CENTRO DE CUSTO
        case DataSharedServicesActions.CADASTRO_GRUPO_CENTRO_DE_CUSTO_RECARREGAR: {
            return {
                ...state,
                cadastroGrupoCentroDeCusto: action.payload,
            };
        }
        // FILIAL
        case DataSharedServicesActions.CADASTRO_FILIAL_RECARREGAR: {
            return {
                ...state,
                cadastroFilial: action.payload,
            };
        }
        // COLABORADOR
        case DataSharedServicesActions.CADASTRO_COLABORADOR_RECARREGAR: {
            return {
                ...state,
                cadastroColaborador: action.payload,
            };
        }
        // FORNECEDOR
        case DataSharedServicesActions.CADASTRO_FORNECEDOR_RECARREGAR: {
            return {
                ...state,
                cadastroFornecedor: action.payload,
            };
        }
        //TIPO DE FORNECEDOR
        case DataSharedServicesActions.CADASTRO_TIPO_FORNECEDOR_RECARREGAR: {
            return {
                ...state,
                cadastroTipoDeFornecedor: action.payload,
            };
        }
        //TIPO DE CONTRATO
        case DataSharedServicesActions.CADASTRO_TIPO_CONTRATO_RECARREGAR: {
            return {
                ...state,
                cadastroTipoDeContrato: action.payload,
            };
        }
        // PRODUTOS
        case DataSharedServicesActions.CADASTRO_PRODUTO_RECARREGAR: {
            return {
                ...state,
                cadastroProduto: action.payload,
            };
        }
        // PROJETO
        case DataSharedServicesActions.CADASTRO_PROJETO_RECARREGAR: {
            return {
                ...state,
                cadastroProjeto: action.payload,
            };
        }
        // GRUPO DE PRODUTO
        case DataSharedServicesActions.CADASTRO_GRUPO_PRODUTO_RECARREGAR: {
            return {
                ...state,
                cadastroGrupoDeProduto: action.payload,
            };
        }
        // CATEGORIA DE PRODUTO
        case DataSharedServicesActions.CADASTRO_CATEGORIA_PRODUTO_RECARREGAR: {
            return {
                ...state,
                cadastroCategoriaDeProduto: action.payload,
            };
        }
        // TIPO DE PRODUTO
        case DataSharedServicesActions.CADASTRO_TIPO_PRODUTO_RECARREGAR: {
            return {
                ...state,
                cadastroTipoDeProduto: action.payload,
            };
        }
        // CONDICAO DE PAGAMENTO
        case DataSharedServicesActions.CADASTRO_CONDICAO_DE_PAGAMENTO_RECARREGAR: {
            return {
                ...state,
                cadastroCondicaoDePagamento: action.payload,
            };
        }
        // TIPO DE CLIENTE
        case DataSharedServicesActions.CRM_TIPO_DE_CLIENTE_RECARREGAR: {
            return {
                ...state,
                crmTipoDeCliente: action.payload,
            };
        }
        // GRUPO DE CLIENTE
        case DataSharedServicesActions.CRM_GRUPO_DE_CLIENTE_RECARREGAR: {
            return {
                ...state,
                crmGrupoDeCliente: action.payload,
            };
        }
        // SITUAÇÃO DE CLIENTE
        case DataSharedServicesActions.CRM_SITUACAO_DE_CLIENTE_RECARREGAR: {
            return {
                ...state,
                crmSituacaoDeCliente: action.payload,
            };
        }

        //FORMA DE PAGAMENTO
        case DataSharedServicesActions.COMPRAS_TIPO_DE_PAGAMENTO_RECARREGAR: {
            return {
                ...state,
                comprasFormaDePagamento: action.payload,
            };
        }
        // REGRA DE APROVACAO
        case DataSharedServicesActions.COMPRAS_REGRA_DE_APROVACAO_RECARREGAR: {
            return {
                ...state,
                comprasRegraDeAprovacao: action.payload,
            };
        }
        // TIPO DE COMPRA
        case DataSharedServicesActions.COMPRAS_TIPO_DE_COMPRA_RECARREGAR: {
            return {
                ...state,
                comprasTipoDeCompra: action.payload,
            };
        }
        // REQUISICAO PRODUTO
        case DataSharedServicesActions.COMPRAS_REQUISICAO_ABERTAS_RECARREGAR: {
            return {
                ...state,
                comprasRequisicaoAbertas: action.payload,
            };
        }
        // ORDEM ABERTAS
        case DataSharedServicesActions.COMPRAS_ORDEM_ABERTAS_RECARREGAR: {
            return {
                ...state,
                comprasOrdemAbertas: action.payload,
            };
        }
        // ORDEM ENCERRADAS
        case DataSharedServicesActions.COMPRAS_ORDEM_ENCERRADAS_RECARREGAR: {
            return {
                ...state,
                comprasOrdemEncerradas: action.payload,
            };
        }
        // COTACOES
        case DataSharedServicesActions.COMPRAS_COTACOES_RECARREGAR: {
            return {
                ...state,
                comprasCotacoes: action.payload,
            };
        }
        // PEDIDO ABERTOS
        case DataSharedServicesActions.COMPRAS_PEDIDO_ABERTOS_RECARREGAR: {
            return {
                ...state,
                comprasPedidoAbertos: action.payload,
            };
        }
        // PEDIDOS ENCERRADOS
        case DataSharedServicesActions.COMPRAS_PEDIDO_ENCERRADOS_RECARREGAR: {
            return {
                ...state,
                comprasPedidoEncerrados: action.payload,
            };
        }
        // RECEBIMENTO PENDENTES
        case DataSharedServicesActions.COMPRAS_RECEBIMENTO_PENDENTES_RECARREGAR: {
            return {
                ...state,
                comprasRecebimentoPendentes: action.payload,
            };
        }
        // RECEBIMENTO PROCESSADOS
        case DataSharedServicesActions.COMPRAS_RECEBIMENTO_PROCESSADOS_RECARREGAR: {
            return {
                ...state,
                comprasRecebimentoProcessados: action.payload,
            };
        }
        // ORDEM SUGESTOES
        case DataSharedServicesActions.COMPRAS_ORDEM_SUGESTOES_RECARREGAR: {
            return {
                ...state,
                comprasOrdemSugestoes: action.payload,
            };
        }
        // ORDEM PRODUTOS
        case DataSharedServicesActions.COMPRAS_ORDEM_PRODUTOS_RECARREGAR: {
            return {
                ...state,
                comprasOrdemProdutos: action.payload,
            };
        }
        // PLANEJAMENTO ORCAMENTARIO
        case DataSharedServicesActions.COMPRAS_PLANEJAMENTO_RECARREGAR: {
            return {
                ...state,
                comprasPlanejamento: action.payload,
            };
        }
        case DataSharedServicesActions.COMPRAS_MOTIVO_RECARREGAR: {
            return {
                ...state,
                comprasMotivo: action.payload,
            };
        }

        case DataSharedServicesActions.COMPRAS_NIVEL_DE_APROVACAO_RECARREGAR: {
            return {
                ...state,
                comprasNivelDeAprovacao: action.payload,
            };
        }

        // REQUSICAO DE ESTOQUE ABERTAS
        case DataSharedServicesActions.ESTOQUE_REQUISICAO_ABERTAS_RECARREGAR: {
            return {
                ...state,
                estoqueRequisicaoAbertas: action.payload,
            };
        }

        // ALMOXARIFADO
        case DataSharedServicesActions.ESTOQUE_ALMOXARIFADO_RECARREGAR: {
            return {
                ...state,
                estoqueAlmoxarifado: action.payload,
            };
        }
        // MAPEAMENTO
        case DataSharedServicesActions.ESTOQUE_MAPEAMENTO_RECARREGAR: {
            return {
                ...state,
                estoqueMapeamento: action.payload,
            };
        }
        // SECAO
        case DataSharedServicesActions.ESTOQUE_SECAO_RECARREGAR: {
            return {
                ...state,
                estoqueSecao: action.payload,
            };
        }
        // CLASSIF. AUXILIAR
        case DataSharedServicesActions.ESTOQUE_CLASSIF_AUX_RECARREGAR: {
            return {
                ...state,
                estoqueClassificacaoAuxiliar: action.payload,
            };
        }
        // ENTRADA DE ESTOQUE
        case DataSharedServicesActions.ESTOQUE_ENTRADA_RECARREGAR: {
            return {
                ...state,
                estoqueEntrada: action.payload,
            };
        }
        // NIVEL DE APROVACAO
        case DataSharedServicesActions.ESTOQUE_NIVEL_DE_APROVACAO_RECARREGAR    : {
            return {
                ...state,
                estoqueNivelDeAprovacao: action.payload,
            };
        }
        // SAIDA DE ESTOQUE
        case DataSharedServicesActions.ESTOQUE_SAIDA_RECARREGAR: {
            return {
                ...state,
                estoqueSaida: action.payload,
            };
        }
        // TRANSFERENCIA DE ESTOQUE
        case DataSharedServicesActions.ESTOQUE_TRANSFERENCIA_RECARREGAR: {
            return {
                ...state,
                estoqueTransferencia: action.payload,
            };
        }
        // SALDOS DE ESTOQUE
        case DataSharedServicesActions.ESTOQUE_SALDOS_RECARREGAR: {
            return {
                ...state,
                estoqueSaldos: action.payload,
            };
        }
        // EXPEDICAO PENDENTES
        case DataSharedServicesActions.ESTOQUE_EXPEDICAO_PENDENTES_RECARREGAR: {
            return {
                ...state,
                estoqueExpedicaoPendentes: action.payload,
            };
        }
        // EXPEDICAO PROCESSADOS
        case DataSharedServicesActions.ESTOQUE_EXPEDICAO_PROCESSADOS_RECARREGAR: {
            return {
                ...state,
                estoqueExpedicaoProcessados: action.payload,
            };
        }
        case DataSharedServicesActions.ESTOQUE_MOTIVO_RECARREGAR: {
            return {
                ...state,
                estoqueMotivo: action.payload,
            };
        }
        case DataSharedServicesActions.ESTOQUE_TIPO_DE_REQUISICAO_RECARREGAR: {
            return {
                ...state,
                estoqueTipoDeRequisicao: action.payload,
            };
        }
        case DataSharedServicesActions.ESTOQUE_REGRA_DE_APROVACAO_RECARREGAR: {
            return {
                ...state,
                estoqueRegraDeAprovacao: action.payload,
            };
        }

        // CRM CLIENTE
        case DataSharedServicesActions.CRM_CLIENTE_RECARREGAR: {
            return {
                ...state,
                crmCliente: action.payload,
            };
        }

        // CRM CATEGORIA DE CLIENTE
        case DataSharedServicesActions.CRM_CATEGORIA_DE_CLIENTE_RECARREGAR: {
            return {
                ...state,
                crmCategoriaDeCliente: action.payload,
            };
        }

        // CRM SITUACAO DE CLIENTE
        case DataSharedServicesActions.CRM_SITUACAO_DE_CLIENTE_RECARREGAR: {
            return {
                ...state,
                crmSituacaoDeCliente: action.payload,
            };
        }

        // FINANCEIRO AGENCIA
        case DataSharedServicesActions.FINANCEIRO_AGENCIA_RECARREGAR: {
            return {
                ...state,
                financeiroAgencia: action.payload,
            };
        }
        // FINANCEIRO CUSTO ABC
        case DataSharedServicesActions.FINANCEIRO_CUSTO_ABC_RECARREGAR: {
            return {
                ...state,
                financeiroCustoABC: action.payload,
            };
        }
        // FINANCEIRO CAIXA-BANCOS
        case DataSharedServicesActions.FINANCEIRO_CAIXA_BANCOS_RECARREGAR: {
            return {
                ...state,
                financeiroCaixaBancos: action.payload,
            };
        }
        // CLASSIF. AUXILIAR
        case DataSharedServicesActions.FINANCEIRO_CLASSIFICACAO_AUXILIAR_RECARREGAR: {
            return {
                ...state,
                financeiroClassificacaoAuxiliar: action.payload,
            };
        }
        // FINANCEIRO CONTA CORRENTE
        case DataSharedServicesActions.FINANCEIRO_CONTA_CORRENTE_RECARREGAR: {
            return {
                ...state,
                financeiroContaCorrente: action.payload,
            };
        }
        // FINANCEIRO CR
        case DataSharedServicesActions.FINANCEIRO_CR_RECARREGAR: {
            return {
                ...state,
                financeiroCr: action.payload,
            };
        }
        // FINANCEIRO CR-BX
        case DataSharedServicesActions.FINANCEIRO_CR_BX_RECARREGAR: {
            return {
                ...state,
                financeiroCrBx: action.payload,
            };
        }
        // FINANCEIRO CR-PREJUIZOS
        case DataSharedServicesActions.FINANCEIRO_CR_PREJUIZOS_RECARREGAR: {
            return {
                ...state,
                financeiroCrPrejuizos: action.payload,
            };
        }
        // FINANCEIRO CP
        case DataSharedServicesActions.FINANCEIRO_CP_RECARREGAR: {
            return {
                ...state,
                financeiroCp: action.payload,
            };
        }
        // FINANCEIRO CP-BX
        case DataSharedServicesActions.FINANCEIRO_CP_BX_RECARREGAR: {
            return {
                ...state,
                financeiroCpBx: action.payload,
            };
        }
        // FINANCEIRO ITEM LCTO
        case DataSharedServicesActions.FINANCEIRO_ITEM_LCTO_RECARREGAR: {
            return {
                ...state,
                financeiroItemLancamento: action.payload,
            };
        }

        // VENDAS FATURA ABERTAS
        case DataSharedServicesActions.VENDAS_FATURA_ABERTAS_RECARREGAR: {
            return {
                ...state,
                vendasFaturaAbertas: action.payload,
            };
        }
        // VENDAS FATURA PROCESSADAS
        case DataSharedServicesActions.VENDAS_FATURA_PROCESSADAS_RECARREGAR: {
            return {
                ...state,
                vendasFaturaProcessadas: action.payload,
            };
        }
        // VENDAS FATURA CANCELADAS
        case DataSharedServicesActions.VENDAS_FATURA_CANCELADAS_RECARREGAR: {
            return {
                ...state,
                vendasFaturaCanceladas: action.payload,
            };
        }
        // VENDAS ORDEM ABERTAS
        case DataSharedServicesActions.VENDAS_ORDEM_ABERTAS_RECARREGAR: {
            return {
                ...state,
                vendasOrdemAbertas: action.payload,
            };
        }
        // VENDAS ORDEM PROCESSADAS
        case DataSharedServicesActions.VENDAS_ORDEM_PROCESSADAS_RECARREGAR: {
            return {
                ...state,
                vendasOrdemProcessadas: action.payload,
            };
        }
        // VENDAS ORDEM CANCELADAS
        case DataSharedServicesActions.VENDAS_ORDEM_CANCELADAS_RECARREGAR: {
            return {
                ...state,
                vendasOrdemCanceladas: action.payload,
            };
        }
        // VENDAS NOTA SERVICO PENDENTES
        case DataSharedServicesActions.VENDAS_NOTA_SERVICO_PENDENTES_RECARREGAR: {
            return {
                ...state,
                vendasNotaServicoPendentes: action.payload,
            };
        }
        // VENDAS NOTA SERVICO EMITIDAS
        case DataSharedServicesActions.VENDAS_NOTA_SERVICO_EMITIDAS_RECARREGAR: {
            return {
                ...state,
                vendasNotaServicoEmitidas: action.payload,
            };
        }
        // VENDAS NOTA SERVICO CANCELADAS
        case DataSharedServicesActions.VENDAS_NOTA_SERVICO_CANCELADAS_RECARREGAR: {
            return {
                ...state,
                vendasNotaServicoCanceladas: action.payload,
            };
        }
        // VENDAS PEDIDOS PRODUTO PENDENTES
        case DataSharedServicesActions.VENDAS_PEDIDO_ABERTAS_RECARREGAR: {
            return {
                ...state,
                vendasPedidoAbertos: action.payload,
            };
        }
        // VENDAS PEDIDOS PRODUTO PROCESSADAS
        case DataSharedServicesActions.VENDAS_PEDIDO_PROCESSADAS_RECARREGAR: {
            return {
                ...state,
                vendasPedidoProcessados: action.payload,
            };
        }
        // VENDAS PEDIDOS PRODUTO CANCELADAS
        case DataSharedServicesActions.VENDAS_PEDIDO_CANCELADAS_RECARREGAR: {
            return {
                ...state,
                vendasPedidoCancelados: action.payload,
            };
        }
        // VENDAS NOTA PRODUTO PENDENTES
        case DataSharedServicesActions.VENDAS_NOTA_PRODUTO_PENDENTES_RECARREGAR: {
            return {
                ...state,
                vendasNotaProdutoPendentes: action.payload,
            };
        }
        // VENDAS NOTA PRODUTO EMITIDAS
        case DataSharedServicesActions.VENDAS_NOTA_PRODUTO_EMITIDAS_RECARREGAR: {
            return {
                ...state,
                vendasNotaProdutoEmitidas: action.payload,
            };
        }
        // VENDAS NOTA PRODUTO CANCELADAS
        case DataSharedServicesActions.VENDAS_NOTA_PRODUTO_CANCELADAS_RECARREGAR: {
            return {
                ...state,
                vendasNotaProdutoCanceladas: action.payload,
            };
        }

        // ERRO
        case DataSharedServicesActions.ERRO: {
            return handleErroAction(state, action);
        }

        default: {
            return state;
        }
    }
}

function handleErroAction(
    state: DataSharedServicesState,
    action: any
): DataSharedServicesState {
    return {
        ...state,
        error: action.payload,
    };
}
