export const DATA_SHARED_SERVICES_STORE = 'DATA_SHARED_SERVICES_STORE';

export interface DataSharedServicesState {
    cadastroCentroDeCusto: boolean;
    cadastroGrupoCentroDeCusto: boolean;
    cadastroFilial: boolean;
    cadastroColaborador: boolean;
    cadastroFornecedor: boolean;
    cadastroTipoDeFornecedor: boolean;
    cadastroTipoDeContrato: boolean;
    cadastroProduto: boolean;
    cadastroProjeto: boolean;
    cadastroGrupoDeProduto: boolean;
    cadastroCategoriaDeProduto: boolean;
    cadastroTipoDeProduto: boolean;
    cadastroCondicaoDePagamento: boolean;

    estoqueRequisicaoAbertas: boolean;
    estoqueAlmoxarifado: boolean;
    estoqueMapeamento: boolean;
    estoqueSecao: boolean;
    estoqueClassificacaoAuxiliar: boolean;
    estoqueEntrada: boolean;
    estoqueSaida: boolean;
    estoqueTransferencia: boolean;
    estoqueAjuste: boolean;
    estoqueSaldos: boolean;
    estoqueExpedicaoPendentes: boolean;
    estoqueExpedicaoProcessados: boolean;
    estoqueMotivo: boolean;
    estoqueTipoDeRequisicao: boolean;
    estoqueNivelDeAprovacao: boolean;
    estoqueRegraDeAprovacao: boolean;

    comprasFormaDePagamento: boolean;
    comprasRegraDeAprovacao: boolean;
    comprasTipoDeCompra: boolean;
    comprasRequisicaoAbertas: boolean;
    comprasOrdemAbertas: boolean;
    comprasOrdemEncerradas: boolean;
    comprasCotacoes: boolean;
    comprasPedidoAbertos: boolean;
    comprasPedidoEncerrados: boolean;
    comprasRecebimentoPendentes: boolean;
    comprasRecebimentoProcessados: boolean;
    comprasOrdemSugestoes: boolean;
    comprasOrdemProdutos: boolean;
    comprasPlanejamento: boolean;
    comprasMotivo: boolean;
    comprasNivelDeAprovacao: boolean;

    crmCliente: boolean;
    crmCategoriaDeCliente: boolean;
    crmTipoDeCliente: boolean;
    crmSituacaoDeCliente: boolean;
    crmGrupoDeCliente: boolean;

    financeiroFormaDePagamento: boolean;
    financeiroClassificacaoAuxiliar: boolean;
    financeiroAgencia: boolean;
    financeiroCustoABC: boolean;
    financeiroCaixaBancos: boolean;
    financeiroContaCorrente: boolean;
    financeiroPlanoDeContas: boolean;
    financeiroCr: boolean;
    financeiroCrBx: boolean;
    financeiroCrPrejuizos: boolean;
    financeiroCp: boolean;
    financeiroCpBx: boolean;
    financeiroItemLancamento: boolean;

    vendasFaturaAbertas: boolean;
    vendasFaturaProcessadas: boolean;
    vendasFaturaCanceladas: boolean;
    vendasOrdemAbertas: boolean;
    vendasOrdemProcessadas: boolean;
    vendasOrdemCanceladas: boolean;
    vendasPedidoAbertos: boolean;
    vendasPedidoProcessados: boolean;
    vendasPedidoCancelados: boolean;
    vendasNotaServicoPendentes: boolean;
    vendasNotaServicoEmitidas: boolean;
    vendasNotaServicoCanceladas: boolean;
    vendasNotaProdutoPendentes: boolean;
    vendasNotaProdutoEmitidas: boolean;
    vendasNotaProdutoCanceladas: boolean;

    error: any;
}

export const dataSharedServicesInitialState: DataSharedServicesState = {
    cadastroCentroDeCusto: true,
    cadastroGrupoCentroDeCusto: true,
    cadastroFilial: true,
    cadastroColaborador: true,
    cadastroFornecedor: true,
    cadastroTipoDeFornecedor: true,
    cadastroTipoDeContrato: true,
    cadastroProduto: true,
    cadastroProjeto: true,
    cadastroGrupoDeProduto: true,
    cadastroCategoriaDeProduto: true,
    cadastroTipoDeProduto: true,
    cadastroCondicaoDePagamento: true,

    estoqueRequisicaoAbertas: true,
    estoqueAlmoxarifado: true,
    estoqueMapeamento: true,
    estoqueSecao: true,
    estoqueClassificacaoAuxiliar: true,
    estoqueEntrada: true,
    estoqueSaida: true,
    estoqueTransferencia: true,
    estoqueAjuste: true,
    estoqueSaldos: true,
    estoqueExpedicaoPendentes: true,
    estoqueExpedicaoProcessados: true,
    estoqueMotivo: true,
    estoqueTipoDeRequisicao: true,
    estoqueNivelDeAprovacao: true,
    estoqueRegraDeAprovacao: true,

    comprasFormaDePagamento: true,
    comprasRegraDeAprovacao: true,
    comprasTipoDeCompra: true,
    comprasRequisicaoAbertas: true,
    comprasOrdemAbertas: true,
    comprasOrdemEncerradas: true,
    comprasCotacoes: true,
    comprasPedidoAbertos: true,
    comprasPedidoEncerrados: true,
    comprasRecebimentoPendentes: true,
    comprasRecebimentoProcessados: true,
    comprasOrdemSugestoes: true,
    comprasOrdemProdutos: true,
    comprasPlanejamento: true,
    comprasMotivo: true,
    comprasNivelDeAprovacao: true,

    crmCliente: true,
    crmCategoriaDeCliente: true,
    crmTipoDeCliente: true,
    crmSituacaoDeCliente: true,
    crmGrupoDeCliente: true,

    financeiroFormaDePagamento: true,
    financeiroClassificacaoAuxiliar: true,
    financeiroAgencia: true,
    financeiroCustoABC: true,
    financeiroCaixaBancos: true,
    financeiroContaCorrente: true,
    financeiroPlanoDeContas: true,
    financeiroCr: true,
    financeiroCrBx: true,
    financeiroCrPrejuizos: true,
    financeiroCp: true,
    financeiroCpBx: true,
    financeiroItemLancamento: true,

    vendasFaturaAbertas: false,
    vendasFaturaProcessadas: false,
    vendasFaturaCanceladas: false,
    vendasOrdemAbertas: false,
    vendasOrdemProcessadas: false,
    vendasOrdemCanceladas: false,
    vendasPedidoAbertos: false,
    vendasPedidoProcessados: false,
    vendasPedidoCancelados: false,
    vendasNotaServicoPendentes: false,
    vendasNotaServicoEmitidas: false,
    vendasNotaServicoCanceladas: false,
    vendasNotaProdutoPendentes: false,
    vendasNotaProdutoEmitidas: false,
    vendasNotaProdutoCanceladas: false,

    error: undefined,
};
